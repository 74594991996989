import { apis } from '@/api';
import { IReferential } from '@/types';
import { i18n } from './utils/i18n';
import { InjectionKey } from 'vue';
import { GroupItemProvide } from '@/composables/group';
import { sortReferential } from '@/utils/helpers';

export const LS: { [key: string]: any } = Object.freeze({ REDIRECT_AFTER_LOGIN: 'redirectAfterLogin' });

export const API = Object.freeze({ DATE_FORMAT: 'YYYY-MM-DD' });

export const FOG_OVERLAY_EVENTS = { CLICK: 'fog-overlay::click' };
export const FORM_WRAPPER_EVENTS = {
  CLEAR_ERRORS: 'form-wrapper::clear-errors',
  SUBMIT: 'form-wrapper::submit',
  UPDATE: 'form-wrapper::update'
};
export enum BTN_MULTI_STATES {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success'
}
export enum FORM_WRAPPER_EVENTS_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  INVALID = 'invalid',
  SUBMITTED = 'submitted'
}
export const GETTER_WRAPPER_EVENTS = { LOAD: 'getter-wrapper::load', UPDATE: 'getter-wrapper::update' };

export enum GETTER_WRAPPER_EVENTS_TYPES {
  SUCCESS = 'success',
  DATA = 'data',
  ERROR = 'error',
  LOADING = 'loading'
}

export enum GETTER_WRAPPER_LOAD_TYPES {
  FULL = 'full',
  PARTIAL = 'partial',
  HIDDEN = 'hidden'
}
export const SCROLL_WATCHER = { REACH_BOTTOM: 'scroll-watcher::reach-bottom' };

export const REFERENTIALS_EVENTS = { RELOAD: 'referentials::reload' };

export const PROJECT_SECTIONS = {
  DATES: 'dates',
  GENERAL_DATA: 'generalData',
  ELIGIBILITY: 'eligibility',
  DOCUMENT_LIST: 'documentList',
  DEPOSIT_HELP: 'depositHelp',
  STUDY_HELPS: 'studyHelps',
  INTERNAL_DATA: 'internalData',
  TAGS: 'tags',
  VALIDATION_MONITORING: 'validationMonitoring',
  HISTORIC: 'historic'
};

export const TYPE_PROJET_WITH_STUDY_HELPS = ['ETDEF', 'ETAMO'];
export const TYPE_PROJET_REQUIRING_QUOTE = ['ETDEF', 'ETAMO'];
export const TYPE_PROJET_REQUIRING_QUOTE_FALLBACK = 'ETETG';

export const POPUP_EVENTS = { CLICK: 'popup::click', CANCEL: 'popup::cancel' };

export const LOG_EVENTS = { REFRESH: 'logs::refresh' };

export const TYPE_DEPOT: Record<string, string> = {
  opale: 'OPALE',
  autre: 'Site Partenaire'
};

export const CODE_GEO_NATIONAL = '1';
export const CODE_GEO_REGIONAL = '4';

export const TYPE_AIDE = {
  AAP: 'AAP',
  GAG: 'GAG'
};

export const ACCOUNTING_MODES = [
  { id: 'AECP', value: i18n.t('project.internalData.labels.budgetaryIdentification.modeGestionComptable.AECP') },
  { id: 'IA', value: i18n.t('project.internalData.labels.budgetaryIdentification.modeGestionComptable.IA') },
  { id: 'CT', value: i18n.t('project.internalData.labels.budgetaryIdentification.modeGestionComptable.CT') }
] as const;

export const YESNO = [
  { id: true, value: i18n.t('yes') },
  { id: false, value: i18n.t('no') }
] as const;

export interface IReferentialConfig {
  url: string;
  storeKey: string;
  groupBy?: string;
  admin?: boolean | { logoUrl: (id: string) => string };
  dataTransformer?(datas: any): any;
}

export interface IReferentialConfigTree {
  [key: string]: IReferentialConfig;
}

export const referentials: IReferentialConfigTree = {
  COUVERTURE_GEO: {
    url: apis.referentials.geographicCoverage,
    storeKey: 'couvertureGeo'
  },
  ZONE_GEO: {
    url: apis.referentials.geographicArea,
    storeKey: 'zoneGeo'
  },
  THEMATIQUE: {
    url: apis.referentials.theme,
    storeKey: 'thematique',
    groupBy: 'parent',
    admin: true,
    dataTransformer(datas: any) {
      return sortReferential(datas, this.groupBy, 'AUT');
    }
  },
  TYPE_PROJET: {
    url: apis.referentials.projectType,
    storeKey: 'typeProjet',
    admin: true
  },
  CIBLE_PROJET: {
    url: apis.referentials.projectTarget,
    storeKey: 'cibleProjet',
    admin: true
  },
  SOUS_TYPE: {
    url: apis.referentials.subType,
    storeKey: 'sousType',
    admin: true
  },
  FOND: {
    url: apis.referentials.funds,
    storeKey: 'fond',
    admin: true
  },
  FINANCEMENT: {
    url: apis.referentials.finance,
    storeKey: 'financement',
    admin: { logoUrl: apis.referentials.financeLogo }
  },
  UG: {
    url: apis.referentials.ug,
    storeKey: 'uniteGestion',
    admin: true,
    dataTransformer: (datas) => {
      Object.keys(datas).forEach((index) => {
        datas[index].toString = () => 'UG : ' + datas[index].code + ' - ' + datas[index].libelle;
      });
      return datas;
    }
  },
  TYPE_DOCUMENT: {
    url: apis.referentials.typeDoc,
    storeKey: 'typeDocument',
    dataTransformer: (datas) => datas.sort((a: IReferential, b: IReferential) => (a.libelle > b.libelle ? 1 : -1))
  },
  LISTE_DEPENSE: {
    url: apis.referentials.listeDepense,
    storeKey: 'listeDepense',
    groupBy: 'parent',
    admin: true,
    dataTransformer(datas: any) {
      return sortReferential(datas, this.groupBy);
    }
  },
  EVENT_TYPE: {
    url: apis.referentials.eventType,
    storeKey: 'eventType'
  },
  MANAGEMENT_FEE: {
    url: apis.referentials.managementFee,
    storeKey: 'managementFee',
    admin: true
  },
  CODE_NAF: {
    url: apis.referentials.codeNaf,
    storeKey: 'codeNaf',
    groupBy: 'parent',
    admin: true
  }
};

export const DISPLAY_TYPE = {
  CARD: 'card',
  TABLE: 'table'
};

export const PREVIEW_TYPE = {
  STANDARD: 'standard',
  AGIR: 'agir'
};

export const allowed_image_mimeTypes = 'image/jpeg, image/pjpeg, image/png, image/svg+xml, image/svg';

export const CONSTRAINTS = {
  NOT_UNIQUE_ERROR: '23bd9dbf-6b9b-41cd-a99e-4844bcf3077f'
};

export const CardAccordionListSymbol: InjectionKey<GroupItemProvide> = Symbol.for('r2da:CardAccordionList');
